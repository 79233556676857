


































import {Component, Prop, Vue, Emit} from 'vue-property-decorator'
import TooltipText from '@/components/common//containers/TooltipText.vue'
@Component({
    components:{
        TooltipText
    }
})
export default class Navbar extends Vue {
    @Prop(String) readonly navbarTitle!: string
    @Prop({default: null}) readonly subTitle!: string
    @Prop(String) readonly primaryBtnText!: string
    @Prop(String) readonly primaryBtnIcon!: string
    @Prop({default: true}) readonly showNavigation!: boolean
    @Prop({default: "mdi-arrow-left"}) readonly navigationIcon!:string
    @Emit("primaryEvent")
    primaryEvent(){
        return true;
    }

    @Emit("navigate")
    public navigate(){
        return;
    }
}   
























































import {Vue, Prop, Emit, Component, Watch} from 'vue-property-decorator'

@Component
export default class DropDown extends Vue{
    @Prop() readonly items!:Array<any>
    @Prop() readonly placeholder!:string
    @Prop() readonly label!:string

    @Emit('change')
    change(selectedItems: Array<any>){
        return selectedItems;
    }

    private selectedItems: Array<any> = []
    private isFocused: boolean = false;

    public checkBoxStyle(item: any, list: Array<any>){
        if(list.find(i => i.id == item.id)){
            return "color: var(----primaryFABlue);";
        }else{
            return "color: var(--highlightGray);";
        }
    }
    public checkBoxIcon(item: any, list: Array<any>){
        if(list.find(i => i.id == item.id)){
            return "mdi-checkbox-marked";
        }else{
            return "mdi-checkbox-blank";
        }
    }
    public clickItem(items: Array<any>){
        this.selectedItems = items;
        this.$emit('change',this.selectedItems)
    }
    public showSelected(){
      let joinedItems = this.selectedItems.map(i => i.name).join(", ");
           return `(${this.selectedItems.length})${joinedItems}`.substr(0,26) + (joinedItems.length > 22 ? '..' : '');
    }
}

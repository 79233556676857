











import {Component, Prop, Vue} from 'vue-property-decorator'
@Component({
    components:{

    }
})
export default class ListItemContent extends Vue{
    @Prop({required: true}) readonly title!: string
    @Prop({required: false}) readonly subtitle!: string
}

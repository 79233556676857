















import { Component, Prop, Vue, Emit } from "vue-property-decorator";
@Component
export default class TextButton extends Vue {
  @Prop({ default: "primary", required: false }) readonly btnColor!: string;
  @Prop({ required: true }) readonly btnText!: string;

  @Emit("callAction")
  callAction() {
    return;
  }
}

import client from './../apiService';

class CouponManagementService{
    async GetCoupon(searchTerm: string, page: number){
        const url = `Coupon/GetCoupon?searchTerm=${searchTerm}&page=${page}`;
        var result =  await client.get(url, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return result;
    }
    async GetGift(){
        const url = `Coupon/GetAllActiveGift`;
        var result =  await client.get(url, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return result;
    }

    deactivateCoupon(couponId: number){
        const url = `Coupon/DeactivateCoupon?CouponId=${couponId}`;
        return client.get(url, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
    }

    activateCoupon(couponId: number){
        const url = `Coupon/ActivateCoupon?CouponId=${couponId}`;
        return client.get(url, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
    }

    async getInactiveCoupon(searchTerm: string, page: number){
        const url = `Coupon/GetInactiveCoupon?searchTerm=${searchTerm}&page=${page}`;
        return await client.get(url, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }
    updateCoupon(coupon: object){
        const url = `Coupon/UpdateCoupon`;
        return client.post(url, coupon, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
    }
    createCoupon(coupon: object){
        const url = `Coupon/CreateCoupon`;
        return client.post(url, coupon, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
    }
    createCouponScheme(couponScheme: object){
        const url = `Coupon/CreateCouponScheme`;
        return client.post(url, couponScheme, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
    }

}

export default new CouponManagementService();
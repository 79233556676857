import { render, staticRenderFns } from "./SideDrawer.vue?vue&type=template&id=432b6f8e&scoped=true&"
import script from "./SideDrawer.vue?vue&type=script&lang=ts&"
export * from "./SideDrawer.vue?vue&type=script&lang=ts&"
import style0 from "./SideDrawer.vue?vue&type=style&index=0&id=432b6f8e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "432b6f8e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
installComponents(component, {VContainer,VIcon,VNavigationDrawer})















import {Component, Prop, Emit, Vue} from 'vue-property-decorator'

@Component
export default class Snackbar extends Vue{
    @Prop({required: true}) readonly message!: string
    @Prop({default: false, required: true}) readonly snackbar!: boolean
    @Prop({default: 5000, required: true}) readonly timeout!: number
    @Prop({default: 'success'}) readonly color!: string

    @Emit("closeSnackbar")
    closeSnackbar(val: boolean){
        return val;
    }

    get showSnackbar(): boolean{
        return this.snackbar;
    }

    set showSnackbar(newVal: boolean){
        this.closeSnackbar(newVal);
    }
}
































import {Component, Prop, Emit, Vue} from 'vue-property-decorator'
import Navbar from "@/components/common/Navbar.vue";
@Component({
    components: {Navbar}
})
export default class SideDrawer extends Vue{
    @Prop({default: false, required: true}) readonly sideDrawer!: boolean
    @Prop(String) readonly sidedrawerTitle!: string
    @Prop() readonly subTitle!: string
    @Prop({default: 344}) readonly width!:string | number
    @Prop() readonly usesNavbar!: boolean
    @Prop({default:'Confirm'}) primaryBtnText !: string

    @Emit("closeDrawer")
    closeDrawer(val: boolean){
        return val;
    }
    @Emit("navBarPrimary")
    navBarPrimary(){
        return;
    }

    get showSideDrawer(): boolean{
        return this.sideDrawer;
    }

    set showSideDrawer(newVal: boolean){
        this.closeDrawer(newVal);
    }
    private toggle(e: any){
      console.log(e);
    }
}

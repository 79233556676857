

































import {Component, Vue, Prop, Emit} from 'vue-property-decorator'
import TextButton from '@/components/common/Buttons/TextButton.vue'
@Component({
    components: {
        TextButton
    }
})
export default class ActionablePopup extends Vue {
    @Prop({default: false, required: true}) readonly popupDialog!: boolean
    @Prop() readonly title !: string
    @Prop() readonly subtitle !: string
    @Prop() readonly text !: string
    @Prop({required: false}) readonly data !: any
    @Prop({default: 'Cancel'}) readonly cancelBtnText !: string
    @Prop({default: 'Confirm'}) readonly confirmBtnText !: string

    @Emit("close")   
    public close(val: boolean){
        return val;
    }

    get dialog(): boolean{
        return this.popupDialog;
    }

    set dialog(newVal: boolean){
        this.close(newVal);
    }

    public cancel(){
        this.$emit('close', false);
    }
    public confirm(){
        this.$emit('confirm', this.data);
    }
}
























































































































































































import { Component, Vue, Watch, Prop, Emit } from "vue-property-decorator";
import DropDown from "@/components/common/Inputs/DropDown.vue";
import Navbar from "@/components/common/Navbar.vue";
import ExcelUploadButton from "@/components/common/Buttons/ExcelUploadButton.vue";
import ListItem from "@/components/common/List/ListItem.vue";
import ListItemContent from "@/components/common/List/ListItemContent.vue";
import SideDrawer from "@/components/common/SideDrawer.vue";
import Illustration from "@/components/common/Illustration.vue";
import Snackbar from "@/components/common/Snackbar.vue";
import DistributorService from "@/services/DistributorService";
import CouponManagementService from "@/services/CouponManagementService";
import DetailsCard from "@/components/common/DetailsCard.vue";
import SearchBar from "@/components/common/SearchBar.vue";
import SearchableList from "@/components/common/SearchableList.vue";
import ActionablePopup from "@/components/common/Popup/ActionablePopup.vue";
import DataTable from "@/components/common/Tables/DataTable.vue";
import TextButton from "@/components/common/Buttons/TextButton.vue";
import { ICoupon } from "@/interfaces/Coupon";
import { ILink, ITableHeader } from "@/interfaces/common";
import { VLayout } from "vuetify/lib";

@Component({
  components: {
    SearchBar,
    Illustration,
    Navbar,
    ExcelUploadButton,
    SideDrawer,
    Snackbar,
    DetailsCard,
    DropDown,
    SearchableList,
    ActionablePopup,
    DataTable,
    TextButton,
    ListItem,
    ListItemContent,
  },
})
export default class Create extends Vue {
  // for illustration
  private description: string = "No coupon Found";
  private subdescription: string = "Please create a coupon to begin with";
  //for navbar
  private sidedrawerTitle: string = "Fill coupon Details";
  //for side drawer
  private sideDrawer: boolean = false;
  private valid: boolean = false;
  private timeout!: NodeJS.Timeout;
  private message: string = "";
  private snackbar: boolean = false;
  private snackbarColor: string = "success";
  private showCouponList: boolean = true;
  //flags for create or edit check
  private createCoupon: boolean = true;
  private menuItems: Array<ILink> = [];
  private selectedCouponId: number = 0;
  private couponSearch: string = "";
  private coupon: Array<ICoupon> = [];
  private totalCoupon: number = 0;
  private pages: number = 0;
  private page: number = 1;
  private CouponLoading: boolean = false;
  private selectedActionMenu: number = -1;
  private editedItem: ICoupon = {
    name: "",
    description: "",
    id: 0,
    erpId: "",
    amount: 0
  };
  private defaultItem: ICoupon = {
    name: "",
    description: "",
    erpId: "",
    id: 0,
    amount: 0,
  };
  private confirmationPopup: boolean = false;
  private confirmationPopupTitle: string = "";
  private confirmationPopupSubtitle: string = "";
  private confirmationPopupText: string = "";
  private cancelBtnText: string = "";
  private confirmBtnText: string = "";
  private tableHeaders: Array<ITableHeader> = [
    {
      text: "Name",
      value: "name",
      sortable: false,
      default: "",
    },
    {
      text: "Description",
      value: "description",
      sortable: false,
      default: "",
    },
    {
      text: "ERPId",
      value: "erpId",
      sortable: false,
      default: "",
    },
    {
      text: "Amount",
      value: "amount",
      sortable: false,
      default: "VACANT",
    },
    {
      text: "Actions",
      value: "action",
      sortable: false,
      default: "",
    },
  ];
  private inactiveCoupon: boolean = false;
  private creatingOrUpdatingCoupon: boolean = false;

  get formValidate(): Vue & { validate: () => boolean } {
    return this.$refs.form as Vue & { validate: () => boolean };
  }
  get formReset(): Vue & { reset: () => void } {
    return this.$refs.form as Vue & { reset: () => void };
  }
  get formResetValidation(): Vue & { resetValidation: () => void } {
    return this.$refs.form as Vue & { resetValidation: () => void };
  }
  isNumber(evt: KeyboardEvent): void {
    const keysAllowed: string[] = [
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
    ];
    const keyPressed: string = evt.key;

    if (!keysAllowed.includes(keyPressed)) {
      evt.preventDefault();
    }
  }

  public showCommunicationError(err: any) {
    this.snackbarColor = "red";
    this.snackbar = true;
    this.message = window.navigator.onLine
      ? `Some Error occured`
      : `Cannot reach server at the moment`;
  }

  private createOrUpdateCoupon(): void {
    if (!this.creatingOrUpdatingCoupon)
      this.createCoupon ? this.createNewCoupon() : this.updateCoupon();
  }

  public menuAction(coupon: ICoupon, commandIndex: number) {
    this.selectedCouponId = coupon.id;
    this.selectedActionMenu = commandIndex;
    switch (this.menuItems[commandIndex].Text) {
      case "DEACTIVATE":
        this.confirmationPopupTitle = "Confirm Deactivation";
        this.confirmationPopupSubtitle =
          "Are you sure you wish to deactivate the coupon?";
        this.confirmationPopupText = "";
        this.cancelBtnText = "Cancel";
        this.confirmBtnText = "Confirm";
        this.confirmationPopup = true;
        break;
      default:
        console.log("Unhandled menuAction");
    }
  }
  public activateCoupon(coupon: ICoupon) {
    this.selectedCouponId = coupon.id;
    this.confirmationPopupTitle = "Confirm Activation";
    this.confirmationPopupSubtitle = coupon.name + " coupon will be activated.";
    this.confirmationPopupText = "";
    this.cancelBtnText = "Keep Inactive";
    this.confirmBtnText = "Activate";
    this.confirmationPopup = true;
  }
  public editCoupon(value: ICoupon): void {
    this.createCoupon = false;
    this.openDrawer("");
    this.editedItem = Object.assign(
      {},
      {
        name: value.name,
        description: value.description,
        erpId: value.erpId,
        amount: value.amount,
        id: value.id,
      }
    );
  }
  public updateCoupon(): void {
    let value = Object.assign({}, this.editedItem);
    this.creatingOrUpdatingCoupon = true;
    CouponManagementService.updateCoupon({
      name: value.name,
      description: value.description,
      erpId: value.erpId,
      amount: value.amount,
      id: value.id,
    })
      .then((response: any) => {
        this.snackbarColor = "success";
        this.snackbar = true;
        this.message = "Changes Saved Successfully";
        this.showCouponList = true;
        this.creatingOrUpdatingCoupon = false;
        this.closeSideDrawer(false);
        this.clearForm();
        this.refreshCouponList();
      })
      .catch((error: { response: { status: number; data: string } }) => {
        this.creatingOrUpdatingCoupon = false;
        if (error.response.status == 400) {
          this.snackbarColor = "red";
          this.snackbar = true;
          this.message = error.response.data;
        }
      });
  }
  public createNewCoupon(): void {
    let value = Object.assign({}, this.editedItem);
    this.creatingOrUpdatingCoupon = true;
    CouponManagementService.createCoupon({
      name: value.name,
      description: value.description,
      erpId: value.erpId,
      amount: value.amount,
      id: value.id,
    })
      .then((response: any) => {
        this.snackbarColor = "success";
        this.snackbar = true;
        this.message = "Changes Saved Successfully";
        this.showCouponList = true;
        this.creatingOrUpdatingCoupon = false;
        this.closeSideDrawer(false);
        this.clearForm();
        this.refreshCouponList();
      })
      .catch((error: { response: { status: number; data: string } }) => {
        this.creatingOrUpdatingCoupon = false;
        if (error.response.status == 400) {
          this.snackbarColor = "red";
          this.snackbar = true;
          this.message = error.response.data;
        }
      });
  }
  public clearForm(): void {
    this.editedItem = Object.assign({}, this.defaultItem);
  }
  public async refreshCouponList() {
    this.inactiveCoupon
      ? await this.getInactiveCoupon(this.couponSearch, 1)
      : await this.getCoupon(this.couponSearch, 1);
  }
  public openDrawer(value: string): void {
    this.sidedrawerTitle = this.createCoupon
      ? "Fill coupon Details"
      : "Edit coupon Details";
    this.clearForm();
    this.sideDrawer = true;
    this.formResetValidation.resetValidation();
  }
  public closeSideDrawer(value: boolean): void {
    this.hideWindowScrollBar(value);
    this.sideDrawer = value;
  }
  private getRefAsAny(refKey: string) {
    return this.$refs[refKey] as any;
  }

  public closeSnackbar(value: boolean): void {
    this.snackbar = value;
  }

  public async getCoupon(searchTerm: string, value: number): Promise<boolean> {
    this.CouponLoading = true;
    return new Promise<boolean>((res) => {
      CouponManagementService.GetCoupon(this.couponSearch, value)
        .then(
          (response: {
            data: { coupon: ICoupon[]; pages: number; total: number };
          }) => {
            this.coupon = response.data.coupon;

            this.pages = response.data.pages;
            this.totalCoupon = response.data.total;
            this.CouponLoading = false;
            if (this.coupon.length > 0) {
              this.showCouponList = true;
            } else {
              this.showCouponList = false;
              this.description =
                this.couponSearch.length == 0
                  ? "No coupon Found"
                  : "No matching result found";
              this.subdescription =
                this.couponSearch.length == 0
                  ? "Please create a coupon to begin with"
                  : "Make sure you typed correctly or try finding in inactive coupon";
            }
            res(true);
          }
        )
        .catch((error: any) => {
          this.showCommunicationError(error);
          console.log(error);
          this.CouponLoading = false;
        });
    });
  }

  public changePage(value: number): void {
    this.page = value;
    this.inactiveCoupon
      ? this.getInactiveCoupon(this.couponSearch, value)
      : this.getCoupon(this.couponSearch, value);
  }

  public confirmAction(value: any): void {
    if (this.inactiveCoupon) {
      CouponManagementService.activateCoupon(value)
        .then((res: any) => {
          this.snackbarColor = "success";
          this.message = "Changes Saved Successfully";
          this.actionablePopup();
          this.switchCoupon();
        })
        .catch((err: any) => {
          this.showCommunicationError(err);
          console.log(err);
        });
    } else {
      CouponManagementService.deactivateCoupon(value)
        .then((res: any) => {
          this.snackbarColor = "red";
          this.message = "coupon deactivated and moved to inactive list";
          this.actionablePopup();
          this.refreshCouponList();
        })
        .catch((err: any) => {
          this.showCommunicationError(err);
          console.log(err);
        });
    }
  }
  public actionablePopup() {
    this.snackbar = true;
    this.confirmationPopup = false;
    this.showCouponList = true;
  }

  private async GetMenuActions(): Promise<void> {
    this.menuItems = [
      {
        Text: "DEACTIVATE",
        Color: "red",
      },
    ];
  }

  public async getInactiveCoupon(
    searchTerm: string,
    page: number
  ): Promise<boolean> {
    this.CouponLoading = true;
    return new Promise<boolean>((res) => {
      CouponManagementService.getInactiveCoupon(searchTerm, page)
        .then(
          (response: {
            data: { coupon: ICoupon[]; pages: number; total: number };
          }) => {
            this.coupon = response.data.coupon;
            this.pages = response.data.pages;
            this.totalCoupon = response.data.total;
            this.CouponLoading = false;
            res(true);
          }
        )
        .catch((error: any) => {
          this.showCommunicationError(error);
          console.log(error);
          this.CouponLoading = false;
        });
    });
  }
  public switchCoupon() {
    if (this.inactiveCoupon) {
      this.tableHeaders = [
        {
          text: "Name",
          value: "name",
          sortable: false,
          default: "",
        },
        {
          text: "Description",
          value: "description",
          sortable: false,
          default: "",
        },
        {
          text: "ERPId",
          value: "erpId",
          sortable: false,
          default: "",
        },
        {
          text: "Amount",
          value: "amount",
          sortable: false,
          default: "VACANT",
        },
        {
          text: "Actions",
          value: "action",
          sortable: false,
          default: "",
        },
      ];
      this.showCouponList = true;
      this.getInactiveCoupon(this.couponSearch, 1);
    } else {
      this.tableHeaders = [
        {
          text: "Name",
          value: "name",
          sortable: false,
          default: "",
        },
        {
          text: "Description",
          value: "description",
          sortable: false,
          default: "",
        },
        {
          text: "ERPId",
          value: "erpId",
          sortable: false,
          default: "",
        },
        {
          text: "Amount",
          value: "amount",
          sortable: false,
          default: "VACANT",
        },
        {
          text: "Actions",
          value: "action",
          sortable: false,
          default: "",
        },
      ];
      this.getCoupon(this.couponSearch, 1);
    }
  }
  async created() {
    this.CouponLoading = true;
    this.getCoupon(this.couponSearch, 1);
    this.GetMenuActions().then((_) => _);
  }

  private hideWindowScrollBar(hideIt: boolean) {
    window.document.documentElement.style.overflowY = hideIt
      ? "hidden"
      : "scroll";
  }
}













import {Vue, Prop, Emit, Component, Watch} from 'vue-property-decorator'

@Component
export default class SearchBar extends Vue{
    private model: string = ""
    private focused: boolean = false
    @Prop() readonly label!: string
}













import {Vue, Prop, Emit, Component, Watch} from 'vue-property-decorator'

@Component
export default class TooltipText extends Vue{
  @Prop({default:""}) readonly fullText!: string
  @Prop({default: false}) readonly isHTML!: boolean
  @Prop({default: ""}) readonly textClasses!: string
}

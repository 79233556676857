




























































import {Component, Prop, Vue, Emit, Watch} from 'vue-property-decorator'
import SearchBar from "./SearchBar.vue";

@Component({
    components: {
        SearchBar
    }
})
export default class SearchableList extends Vue {
    @Prop() readonly CardTitle!: string
    @Prop({default: "HackToHideSearch"}) readonly SearchLabel!: string
    @Prop() readonly items!: Array<object>
    //Some how this prop was not working as expected so using searchLabel as a Hack
    @Prop() UsesSearchBar!: boolean
    @Prop({required: true}) readonly TitleProperty!: string
    @Prop({default: "index"}) readonly KeyForItem!: string
    @Prop({default: "mdi-plus"}) readonly ItemPrependIcon!: string
    @Prop({default: "lightGreen"}) readonly IconHoverColor!: string
    @Prop({default:""}) readonly NoItemsMessage!: string
    private SearchString: string = ""
    private delayedSearchStringLength: number = 0

    @Watch('SearchString')
    searchStringChange() {
        this.$emit('update:SearchString', this.SearchString)
        if(this.SearchString.length === 0)
            setTimeout((_:any) => this.delayedSearchStringLength = this.SearchString.length,1500);
        else
            this.delayedSearchStringLength = this.SearchString.length;
    }

    @Emit('selectItem')
    selectItem(itemIndex: number) {
        return itemIndex
    }
    @Emit('onScrollList')
    onScrollList(event: any) {
        return event
    }

    public loadMoreItems(event: any) {
        if (event.target.scrollTop + event.target.offsetHeight === event.target.scrollHeight) {
            this.$emit('scrollBottom', this.SearchString)
        }
    }
}

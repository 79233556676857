









import {Component, Prop, Vue} from 'vue-property-decorator'
@Component({
    components:{
    }
})
export default class ListItem extends Vue{
    @Prop({required: true}) readonly title!: string
}

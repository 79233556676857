













import {Component, Prop, Vue} from 'vue-property-decorator'
import ExcelUploadButton from '@/components/common/Buttons/ExcelUploadButton.vue';

@Component({
    components: {
        ExcelUploadButton
    }
})
export default class Illustration extends Vue{
    @Prop(String) readonly description!: string
    @Prop(String) readonly subdescription!: string
    @Prop(String) readonly illustrationImg!: string
}

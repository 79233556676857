























import {Component, Prop, Emit, Vue} from 'vue-property-decorator'
@Component
export default class DetailsCard extends Vue{
    @Prop() readonly model!:any;
    @Prop() readonly title!:string;
}
